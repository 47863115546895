<template>
  <div class="previous-test">
    <Header></Header>
    <!-- Will Show all previous test Title Total, Start Time,Duration,And edit test button -->

    <SearchBar
      v-on:searched="Searched"
      style="margin-top: 10px"
      class="wrap"
      place-holder="Search For test?"
    ></SearchBar>
    <div class="box container my-5">
      <div class="row justify-content-center align-items-center">
    <div
      v-for="(item, index) in filteredList"
      :key="item.id" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 my-3 mx-3">

  <div class="card mx-auto">
    <h3>{{item['title']}}</h3>
    <h5>{{ item["start"].toDate().toString() }}</h5>
    <div class="items">
      <div class="icons" @click="redirect_to_edit(index,2)">
  <span class="iconify" data-icon="bx:bxs-edit" data-inline="false"></span>
      </div>
      <!-- <div class="icons" @click="viewTestStats(index)">
      <span class="iconify" data-icon="carbon:view" data-inline="false"></span>
      </div> -->
      <div class="icons"  @click="deleteit(index)">
      <span class="iconify" data-icon="ant-design:delete-outlined" data-inline="false"></span>

      </div>
    </div>
    </div>
</div>

    </div>
  </div>
  </div>
</template>
<script>
import db from "../../firebase.js";
import Header from "@/components/Header.vue";
import store from "@/store";
import SearchBar from "@/components/SearchBar";
export default {
  components: {
    SearchBar,
    Header
  },
  mounted() {
    db.collection("demo-test")
      .orderBy("start", "desc")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          // (this.li)
          let di = doc.data();
          di["test_id"] = doc.id;
          if (di.deleted == null){
            db.collection("demo-test")
              .doc(doc.id)
              .update({
                deleted: 0
              });
            di.deleted=0
          }
            

          if(di.deleted==0)
            this.li.push(di);
        });
      });
    store.state.loading = false;
  },
  data() {
    return {
      li: [],
      searchQuery: "",
      component: 1
    };
  },
  computed: {
    filteredList() {
      if (this.searchQuery) {
        return this.li.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v));
        });
      } else {
        return this.li;
      }
    }
  },
  methods: {
    Searched(data) {
      this.searchQuery = data;
    },
    redirect_to_edit(index, t) {
        console.log(t);
      if (t == 2)
        this.$router.push({
          name: "EditDemoTest",
          params: {
            test_id: this.li[index]["test_id"],
            test_name: this.li[index]["title"],
            start_time: this.li[index]["start"]["seconds"]
          }
        });
      else if (t == 1) {
        this.$router.push({
          name: "CreateDemoTest",
          params: { test_id: this.li[index]["test_id"] }
        });
      }
    },
    deleteit(index) {
      swal({
        title: "Are you sure?",
        text: "Once test is deleted you would not be able to recover it",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          swal("Yep! test has been deleted!", {
            icon: "success"
          });
          db.collection("demo-test")
            .doc(this.li[index].test_id)
            .delete()
            // .update({
            //   deleted: 1
            // })
            .then(
              (this.li = []),
              db
                .collection("demo-test")
                .orderBy("start", "desc")
                .get()
                .then(querySnapshot => {
                  querySnapshot.forEach(doc => {
                    let di = doc.data();
                    di["test_id"] = doc.id;
                    this.li.push(di);
                  });
                })
            );
        } else {
          swal("Your test is safe!");
        }
      });
    },
    // viewTestStats(index) {
    //   this.$router.push({
    //     name: "Stats",
    //     params: {
    //       id: this.li[index]["test_id"],
    //       title: this.li[index]["title"],
    //       score: 0,
    //       total: 0
    //     }
    //   });
    //   // this.$router.push({name:'Stats',parmas:{"id":this.li[index]["test_id"],"title":this.li[index]["title"]}})
    // },
    inprogress(index) {
      const date = new Date();
      if (this.li[index].start.toDate() <= date) return 1;
      else return 0;
    }
  }
};
</script>
<style scoped>
.previous-test {
  /* background: var(--light-gray); */
  background-image: linear-gradient(white 5%,red);
  background-attachment: fixed;
  position: absolute;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
}
.card{
  height:200px;
  width:250px;
  background:#f2e3e3;
  color: rgb(148, 22, 0);
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  border-radius:10px;
    box-shadow: 0px 0px 2px rgba(110,0,0,0.25);
    transition: 0.4s;
    padding:5px;
    padding-left:10px;
    padding-right:10px;

  
}
.items{
  margin-top:20px;
  display:flex;
  justify-content:space-around;
  width:80%;
}
.items .icons{
  display:flex;
  justify-content:center;
  align-items:center;
  height:30px;
  width:30px;
  background:white;
  border-radius:30px;
  color: rgb(148, 22, 0);
  cursor: pointer;
  transition:0.4s;
}
.items .icons:hover{
  background-color:rgb(148, 22, 0);
  color:white;
}
h5,h3{
  font-weight: 500;
  font-size:10px;
  text-align: center;
  margin-top:5px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
h3{
  font-weight: 900;
  font-size:12px;
  text-transform: uppercase;

}
h5{
   font-family: monospace;
}

</style>
